/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query lublin {
    file(relativePath: { eq: "parking-lotnisko-lublin-front.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parking Lotnisko Lublin",
  tagline: "Lublin, Polska",
  featuredImage: "/assets/parking-lotnisko-lublin-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}

const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <b>Adres:</b> Króla, Jana III Sobieskiego 1, 21-040 Świdnik Port
        lotniczy Lublin – międzynarodowy port lotniczy położony 10 km na wschód
        od centrum Lublina, w mieście Świdnik. Port obsługuje głównie
        aglomerację lubelską. Zarezerwuj parking przy lotnisku lublin w niskiej
        cenie. Listę parkingów znajdziesz poniżej
      </p>
    }
  >
    <h2>
      <strong>
        Parkingi przy lotnisku Lublin: Jak znaleźć idealne miejsce dla swojego
        samochodu?
      </strong>
    </h2>
    <h2>
      <strong>Wstęp</strong>
    </h2>
    <p>
      Planując podróż samolotem, często zapominamy o jednej bardzo ważnej rzeczy
      - bezpiecznym parkowaniu naszego samochodu na czas naszej nieobecności.
      Dlatego, jeśli planujesz swoją następną podróż z lotniska w Lublinie,
      warto zastanowić się, gdzie najlepiej zostawić swój pojazd. W tym artykule
      omówimy najlepsze parkingi przy lotnisku Lublin oraz porady, jak znaleźć
      idealne miejsce dla swojego samochodu.
    </p>
    <h2>
      <strong>Jak znaleźć idealne miejsce dla swojego samochodu?</strong>
    </h2>
    <p>
      Szukając idealnego miejsca parkingowego dla swojego samochodu, warto
      zwrócić uwagę na kilka czynników:
    </p>
    <h3>
      <strong>1. Położenie</strong>
    </h3>
    <p>
      Idealne miejsce parkingowe powinno znajdować się blisko lotniska. Nie
      tylko zaoszczędzisz w ten sposób czas, ale również pieniądze, ponieważ
      ceny parkingów oddalonych od lotniska mogą być wyższe.
    </p>
    <h3>
      <strong>2. Ceny</strong>
    </h3>
    <p>
      Przed wyborem parkingu warto porównać ceny różnych opcji. Pamiętaj, że nie
      zawsze najniższa cena oznacza najlepszą jakość usług.
    </p>
    <h3>
      <strong>3. Ochrona</strong>
    </h3>
    <p>
      Dobry parking przy lotnisku powinien zapewnić całodobową ochronę oraz
      system monitoringu. Dzięki temu możesz mieć pewność, że twój samochód
      będzie bezpieczny na czas Twojej nieobecności.
    </p>
    <h3>
      <strong>4. Transfer na lotnisko</strong>
    </h3>
    <p>
      Jeśli nie chcesz martwić się o transport na lotnisko, warto wybrać
      parking, który oferuje bezpłatny transfer na lotnisko i z powrotem.
    </p>
    <h3>
      <strong>5. Rekomendacje</strong>
    </h3>
    <p>
      Przeczytaj opinie innych użytkowników internetu, którzy korzystali z
      parkingów przy lotnisku w Lublinie. Dzięki temu będziesz miał lepszy obraz
      usług, jakie oferują parkingi w tym regionie.
    </p>
    <h2>
      <strong>Najczęściej zadawane pytania</strong>
    </h2>
    <h3>
      <strong>
        1. Jakie ceny można znaleźć za parkingi przy lotnisku Lublin?
      </strong>
    </h3>
    <p>
      Ceny parkingów przy lotnisku Lublin zaczynają się od około 19 zł za dobę.
      Ostateczna cena zależy od położenia parkingu, jakości usług oraz czasu
      parkowania.
    </p>
    <h3>
      <strong>2. Jakie parkingi oferują transfer na lotnisko?</strong>
    </h3>
    <p>
      Wszystkie parkingi w naszym serwisie oferują darmowy transfer na lotnisko.
    </p>
    <h3>
      <strong>3. Czy mogę dokonać rezerwacji online?</strong>
    </h3>
    <p>
      Tak, większość parkingów przy lotnisku Lublin umożliwia dokonanie
      rezerwacji online. Dzięki temu możesz zarezerwować miejsce parkingowe
      przed swoim wylotem.
    </p>
    <h3>
      <strong>4. Czy parkingi przy lotnisku Lublin są bezpieczne?</strong>
    </h3>
    <p>
      Tak, większość parkingów przy lotnisku Lublin zapewnia całodobową ochronę
      oraz system monitoringu, dzięki czemu Twój samochód będzie bezpieczny na
      czas Twojej nieobecności.
    </p>
    <h2>
      <strong>Podsumowanie</strong>
    </h2>
    <p>
      Wybór idealnego miejsca parkingowego dla swojego samochodu przy lotnisku w
      Lublinie jest bardzo ważny, aby mieć pewność, że Twój samochód będzie
      bezpieczny podczas Twojej nieobecności. W tym artykule omówiliśmy
      najlepsze parkingi przy lotnisku Lublin oraz porady, jak znaleźć idealne
      miejsce parkingowe dla swojego samochodu. Pamiętaj, że przed dokonaniem
      wyboru warto zwrócić uwagę na położenie, ceny, ochronę, transfer na
      lotnisko oraz rekomendacje innych użytkowników.
    </p>
  </Homepage>
)

export default HomePage
